import PropTypes from 'prop-types';
import Input from '../../../components/ui/Input';
import DeleteConfirmationModal from '../../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';

export const ReportsFormRender = ({
									  isLoading,
									  formState,
									  handleChange,
									  handleSubmit,
									  isEdit,
									  handleDelete,
									  isDeleteModalOpen,
									  setIsDeleteModalOpen,
								  }) => {
	return(
		<div className={'w-600px flex flex-col items-center w-full pb-2'}>
			<h2>{isEdit ? 'Edit Training Reports' : 'Create Training Reports'}</h2>
			<form className={'p-5 w-100 grid grid-cols-2 direction-column justify-center align-center gap-x-4'}
				onSubmit={handleSubmit}>
				<Input
					label={'Body'}
					type='text'
					name='body'
					value={formState.body}
					onChange={handleChange}
				/>

				<Input
					label='Scale'
					type='number'
					name='scale'
					value={formState.scale}
					onChange={handleChange}
				/>

				<div className={'flex flex-col align-center justify-center center mt-5 w-full'}>
					<button
						type='submit'
						disabled={isLoading}
						className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${isLoading ? 'opacity-50' : ''}`}
					>
						{isEdit ? 'update' : 'save'}
					</button>
					{ isEdit ? (
						<button
							type='button'
							onClick={() => setIsDeleteModalOpen(true)}
							className={'font-mono max-w-2xl mb-4 px-4 py-2 mt-4 font-medium text-white bg-red-600 rounded hover:bg-red-700'}
						>
							Delete
						</button>
					) : null }
				</div>
			</form>
			<DeleteConfirmationModal
				isOpen={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				onDelete={handleDelete}
				itemName={formState.body}
			/>
		</div>
	);
};

ReportsFormRender.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	formState: PropTypes.shape({
		body: PropTypes.string,
		scale: PropTypes.number
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isEdit: PropTypes.bool.isRequired,
};