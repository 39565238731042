import React from 'react';
import {
	StyledTableRow, UserCounterTitleTable,
	UserPageWrapperElements,
	UserTableMainWrapper
} from '../Users/Users.style';
import PropTypes from 'prop-types';
import {UserFindOneByIdOnlyName, ClassFindOneById, QuestionFindOneByIDOnlyName} from '../../hoc';
import {ArrowIndicatorIcon} from '../../icons';
import {SelectDropDown} from '../../components';
import { format } from 'date-fns';

export const JournalsRender = ({ journals, skip, limit, setLimit, onNextPage, onPreviousPage, allJournals, onMaxScaleChange, onMinScaleChange, minScale, maxScale, sortOrder, setSortOrder, sortOrderByDate, setSortOrderByDate }) => {
	return (
		<div className='w-full h-full m-2'>
			<div className='w-full flex justify-between items-center p-1 mb-3 gap-6'>
				<div className='flex justify-between items-center'>
					<h1 className='font-sans text-white f-500 text-2xl'>Journals</h1>
				</div>
			</div>
			<div className={'flex justify-between items-center mb-6 p-2.5 pr-4 pl-4'}>
				<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
					<span className={'font-sans mb-1'}>Filter By Scale</span>
					<div className={'flex justify-between items-center'}>
						<div>
							<span className={'font-sans mb-1'}>Min Value</span>
							<select
								className={'font-sans f-500 border rounded ml-4 p-2'}
								style={{
									backgroundColor: 'rgba(10, 19, 48, 1)',
									border: '1px solid rgba(11, 23, 57, 1)'
								}}
								value={minScale}
								onChange={(e) => onMinScaleChange(Number(e.target.value))}
							>
								<option value={0}>0</option>
								<option value={1}>1</option>
								<option value={2}>2</option>
								<option value={3}>3</option>
								<option value={4}>4</option>
								<option value={5}>5</option>
								<option value={6}>6</option>
							</select>
						</div>
						<div className={'ml-4'}>
							<span className={'font-sans mb-1'}>Max value</span>
							<select
								className={'font-sans f-500 border rounded ml-4 p-2'}
								style={{
									backgroundColor: 'rgba(10, 19, 48, 1)',
									border: '1px solid rgba(11, 23, 57, 1)'
								}}
								value={maxScale}
								onChange={(e) => onMaxScaleChange(Number(e.target.value))}
							>
								<option value={0}>0</option>
								<option value={1}>1</option>
								<option value={2}>2</option>
								<option value={3}>3</option>
								<option value={4}>4</option>
								<option value={5}>5</option>
								<option value={6}>6</option>
							</select>
						</div>
					</div>
				</div>
				<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
					<span className={'font-sans mb-1'}>Sort by Scale</span>
					<select
						className={'font-sans f-500 border rounded ml-4 p-2'}
						style={{
							backgroundColor: 'rgba(10, 19, 48, 1)',
							border: '1px solid rgba(11, 23, 57, 1)',
						}}
						value={sortOrder}
						onChange={(e) => setSortOrder(e.target.value)}
					>
						<option value='asc'>Ascending</option>
						<option value='desc'>Descending</option>
					</select>
				</div>
				<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
					<div
						className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
						<span className={'font-sans mb-1'}>Sort by Date</span>
						<select
							className={'font-sans f-500 border rounded ml-4 p-2'}
							style={{
								backgroundColor: 'rgba(10, 19, 48, 1)',
								border: '1px solid rgba(11, 23, 57, 1)',
							}}
							value={sortOrderByDate}
							onChange={(e) => setSortOrderByDate(e.target.value)}
						>
							<option value='asc'>Ascending</option>
							<option value='desc'>Descending</option>
						</select>
					</div>
				</div>
				<div className='flex justify-end items-center mb-3'>
					<div className='font-mono f-500 text-sm text-white'>
						<UserCounterTitleTable className={'font-mono'}>
							<span>{skip + 1} - {Math.min(skip + limit, journals.length) + skip} </span>
							<span>of {allJournals}</span>
						</UserCounterTitleTable>
					</div>
				</div>
			</div>

			<div className='w-full mb-4' style={{height: '1px', border: '1px solid rgba(52, 59, 79, 1)'}}/>
			<UserPageWrapperElements className={'overflow-x-auto'}>
				{journals.length > 0 ? (
					<UserTableMainWrapper
						className='overflow-x-auto min-w-full mt rounded-lg p-2.5 border-separate border-spacing-2'>
						<thead>
							<tr>
								<th className='left font-mono main-color-background p-2 t-white'>Journal Question</th>
								<th className='left font-mono main-color-background p-2 t-white'>Lesson</th>
								<th className='left font-mono main-color-background p-2 t-white'>Student Answer</th>
								<th className='left font-mono main-color-background p-2 t-white'>Analysis</th>
								<th className='left font-mono main-color-background p-2 t-white'>Student</th>
								<th className='left font-mono main-color-background p-2 t-white'>Teacher</th>
								<th className='left font-mono main-color-background p-2 t-white'>CreatedAt</th>
								<th className='left font-mono main-color-background p-2 t-white'>Score</th>
							</tr>
						</thead>
						<tbody >
							{journals.map((journal) => (
								<StyledTableRow key={journal._id} className='cursor-pointer'>
									{/* Journal Question */}
									<td className='p-2 text-left max-w-[20rem] overflow-hidden whitespace-normal break-words'>
										<p className='font-mono text-text-light dark:text-text-dark text-xs'>
											<QuestionFindOneByIDOnlyName id={journal.question}/>
										</p>
									</td>
									{/* Lesson */}
									<td className='justify-start items-center text-start max-w-12 p-2'>
										<p className='font-mono text-text-light dark:text-text-dark text-xs'>
											<ClassFindOneById _id={journal.class}>
												{(classData) => (classData ? classData.title : 'Answer not set')}
											</ClassFindOneById>
										</p>
									</td>
									{/* Student Answer */}
									<td className='p-2 text-left max-w-[20rem] overflow-hidden whitespace-normal break-words'>
										<div className='flex justify-start items-center'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{journal.body || 'Body not set yet'}
											</span>
										</div>
									</td>
									{/* Analyze */}
									<td className='p-2 text-left max-w-[20rem] overflow-hidden whitespace-normal break-words'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											{journal.analyze || 'Analyze not set'}
										</span>
									</td>
									{/* Student */}
									<td className='justify-start items-center text-start max-w-12 p-2'>
										<p className='font-mono text-text-light dark:text-text-dark text-xs'>
											<UserFindOneByIdOnlyName id={journal.user}/>
										</p>
									</td>
									{/* Teacher */}
									<td className='justify-start items-center text-start max-w-12 p-2'>
										<p className='font-mono text-text-light dark:text-text-dark text-xs'>
											{journal.teacher ? (
												<UserFindOneByIdOnlyName id={journal.teacher}/>
											) : 'Teacher not set'}
										</p>
									</td>
									{/* Created At */}
									<td className='justify-start items-center text-start max-w-3.5 p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											{journal.createdAt ? format(new Date(journal.createdAt), 'dd/MM/yyyy') : 'Date not set'}
										</span>
									</td>
									{/* Score */}
									<td className='justify-start items-center text-center max-w-2 p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											{journal.scale ?? 'Undefined'}
										</span>
									</td>
								</StyledTableRow>
							))}
						</tbody>
					</UserTableMainWrapper>
				) : (
					<div className='flex justify-center items-center text-white font-mono text-lg p-4'>
						There are no Journals.
					</div>
				)}
			</UserPageWrapperElements>
			<div className='flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'>
				<div className={'font-mono'}>
					<UserCounterTitleTable className={'font-mono'}>
						<span>{skip + 1} - {Math.min(skip + limit, journals.length) + skip} </span>
						<span>of {allJournals}</span>
					</UserCounterTitleTable>
					<SelectDropDown minValue={limit} onChange={setLimit}/>
				</div>
				<div className='flex items-center gap-1'>
					<div
						className='flex justify-center items-center cursor-pointer rounded-lg'
						style={{
							width: '32px',
							height: '32px',
							backgroundColor: 'rgba(10, 19, 48, 1)',
							border: '1px solid rgba(11, 23, 57, 1)',
						}}
						onClick={onPreviousPage}
					>
						<ArrowIndicatorIcon className='transform rotate-180'/>
					</div>
					<div
						className='flex ml-4 justify-center items-center cursor-pointer rounded-lg'
						style={{
							width: '32px',
							height: '32px',
							backgroundColor: 'rgba(10, 19, 48, 1)',
							border: '1px solid rgba(11, 23, 57, 1)',
						}}
						onClick={onNextPage}
					>
						<ArrowIndicatorIcon/>
					</div>
				</div>
			</div>
		</div>
	);
};


JournalsRender.propTypes = {
	journals: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			body: PropTypes.string,
			user: PropTypes.string,
			class: PropTypes.string,
			teacher: PropTypes.string,
			scale: PropTypes.number,
			analyze: PropTypes.string,
			createdAt: PropTypes.string.isRequired,
		})
	).isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number,
	setLimit: PropTypes.func.isRequired,
	onNextPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	totalPages: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	allJournals: PropTypes.number.isRequired,
	onMinScaleChange: PropTypes.func.isRequired,
	onMaxScaleChange: PropTypes.func.isRequired,
	minScale: PropTypes.number.isRequired,
	maxScale: PropTypes.number.isRequired,
	sortOrder: PropTypes.string.isRequired,
	setSortOrder: PropTypes.func.isRequired,
	sortOrderByDate: PropTypes.string.isRequired,
	setSortOrderByDate: PropTypes.func.isRequired,
};