import gql from 'graphql-tag';

const queries = {
	checkInUserAnswerFindMany: gql`
		query CheckInUserAnswerFindMany($filter: FilterFindManycheckinuseranswerInput, $skip: Int, $limit: Int, $sort: SortFindManycheckinuseranswerInput){
			CheckInUserAnswerFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
				    _id
					question
					answer
					user
					group
					scale
					class
					deletedAt
					createdAt
					updatedAt
			}
		}
	`,
	groupFindMany: gql`
		query GroupFindMany($filter: FilterFindManygroupsInput, $skip: Int, $limit: Int,$sort: SortFindManygroupsInput){
			GroupFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
				_id
				manager
				name
				school
				grade
				code
				deleted
				classes {
					unLock
					id
				}
				deletedAt
				organization
				classLink
				curriculums
				createdAt
				updatedAt
			}
		}
	`,
	checkInQuestionFindOne: gql`
		query CheckInQuestionFindOne($filter: FilterFindOnecheckinquestionInput, $skip: Int, $sort: SortFindOnecheckinquestionInput){
			CheckInQuestionFindOne(filter: $filter, skip: $skip, sort: $sort){
				_id
				curriculum
				class
				question
				start
				deletedAt
				createdAt
				updatedAt
			}
		}
	`,
	classesAdminFindOneForCheckingQuestions: gql`
		query ClassesAdminFindOne($filter: FilterFindOneclassesInput, $skip: Int, $sort: SortFindOneclassesInput){
			ClassesAdminFindOne(filter: $filter, skip: $skip, sort: $sort){
				_id
				title
				description
			}
		}
	`,
	groupFindOne: gql`
		query GroupFindOne($filter: FilterFindOnegroupsInput, $skip: Int, $sort: SortFindOnegroupsInput){
			GroupFindOne(filter: $filter, skip: $skip, sort: $sort){
				_id
				manager
				name
				school
				grade
				code
				deleted
				classes {
					unLock
					id
				}
				deletedAt
				organization
				classLink
				curriculums
				createdAt
				updatedAt
				managerObj {
					_id
					id
					firstName
					lastName
					userName
					tag
					bio
					birthday
					email
					password
					resetPasswordToken
					deleted
					appleId
					stripeId
					points
					type
					settings
					lastLogin
					organization
					cleverId
					cleverToken
					classLinkId
					cleverSync
					classLinkSync
					deedlyAuthenticationToken
					deedlyUserId
					acceptTerms
					acceptTermsDate
					createdAt
					updatedAt
				}
			}
		}
	`,
};

export const CheckInQueries = (value) => {
	return queries[ value ];
};