import React from 'react';
import PropTypes  from 'prop-types';
import Input from '../../../../components/ui/Input';
import {FileInput} from '../../../../components/forms/FileInput';

export const DocumentForm=({isLoading,stateForm,onSubmit,handleChange,close, isEditing})=>{

	return (
		<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
			<h1 className={'text-2xl'}>{ isEditing ? 'Updating Document' : 'Add Document' }</h1>
			<FileInput name={'file'} label={'Select Document'}  onChange={handleChange} />
			<Input
				value={stateForm.title}
				name={'title'}
				onChange={handleChange}
				label='Document title'
			/>

			<div className={'flex gap-2'}>
				<button
					type = 'button'
					onClick={close}
					className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}
				>
					cancel
				</button>

				<button
					disabled={isLoading}
					type = 'submit'
					className={'font-mono px-2 py-0.5  text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
				>
					{isLoading? 'loading...' : isEditing ? 'update' : 'save' }
				</button>
			</div>
		</form>
	);
};

DocumentForm.prototype={
	isLoading:PropTypes.bool.isRequired,
	stateForm: PropTypes.shape({
		label:PropTypes.string.isRequired,
		identifier:PropTypes.string.isRequired,
	}),
	handleChange:PropTypes.func.isRequired,
	onSubmit:PropTypes.func.isRequired,
	close:PropTypes.func.isRequired,
	isEditing:PropTypes.bool.isRequired,
};