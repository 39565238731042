import {DocumentForm} from './DocumentForm';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal/Modal';
import {removeTypename} from '../../../../tools';
import {mutations} from '../../../../actions/mutations';

export const DocumentFormLoad = ({id,isOpen,setIsOpen, uploadDocument, selectedDocument, updateDocument, documentsArray, refecth, isCourse})=>{
	const defaultForm = {
		title:'',
		file:undefined,
		type: '',
		url: '',
	};

	const [stateForm,setStateForm] =useState({...defaultForm});
	const [isLoading, setIsLoading] = useState(false);
	const documents = documentsArray || [];

	const handleChange = (e)=>  e.target.type === 'file'?
		setStateForm(prev=>({...prev,[e.target.name]:e.target.files[0]}))
		:
		setStateForm(prev=>({...prev,[e.target.name]:e.target.value}))
	;

	useEffect(() => {
		if (isOpen) {
			const cleanedDocument = selectedDocument ? removeTypename(selectedDocument) : null;
			setStateForm(cleanedDocument || { ...defaultForm });
		}
	}, [isOpen, selectedDocument]);


	const handleUploadDocument=  ({title, file}) => {
		console.log({title,file});
		setIsLoading(true);
		uploadDocument(file, id, title)
		.then(()=>setIsOpen(false))
		.catch(e=>console.log({e}))
		.finally(()=>setIsLoading(false));
	};

	const handleSave = async () => {
		setIsLoading(true);
		try {
			if (selectedDocument) {
				const selectedAndCleanedDocument = removeTypename(selectedDocument);

				const { file, ...updatedDocument } = {
					...selectedAndCleanedDocument,
					...stateForm,
				};

				const updatedDocuments = documents.map(doc =>
					doc.url === selectedAndCleanedDocument.url ? { ...doc, ...updatedDocument } : doc
				);

				if (stateForm.file) {
					handleUploadDocument(stateForm);
				}

				if (isCourse) {
					await updateDocument({
						mutation: mutations.curriculumsUpdateOne,
						variables: {
							filter: { _id: id },
							record: {
								documents: removeTypename(updatedDocuments),
							},
						},
					});
				} else {
					await updateDocument({
						variables: {
							id,
							record: {
								documents: removeTypename(updatedDocuments),
							},
						},
					});
				}
			} else if (stateForm.title && stateForm.file) {
				handleUploadDocument(stateForm);
			}
			setIsOpen(false);
		} catch (error) {
			console.error(error);
		} finally {
			refecth().then(()=>console.log('testing refecth'));
			setIsLoading(false);
		}
	};

	const onSubmit = (e)=>{
		e.preventDefault();
		if( stateForm.title && (stateForm.file || selectedDocument) ){
			handleSave().then(r => console.log('done uploading or editing'));
		}
	};

	return(
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<DocumentForm
				isLoading={isLoading}
				stateForm={stateForm}
				handleChange={handleChange}
				onSubmit={onSubmit}
				close={()=>setIsOpen(false)}
				isEditing={!!selectedDocument}
			/>
		</Modal>
	);
};
DocumentFormLoad.prototype={
	id: PropTypes.string.isRequired,
	onCreate:PropTypes.func.isRequired,
	uploadDocument:PropTypes.func.isRequired,
	updateDocument:PropTypes.func.isRequired,
	selectedDocument: PropTypes.object,
	documentsArray: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string,
			type: PropTypes.string,
			__typename: PropTypes.string,
		})
	),
	refetch: PropTypes.func.isRequired,
	isCourse: PropTypes.bool,
};